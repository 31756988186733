@import url('https://use.typekit.net/cys5gbm.css');
@import url('https://db.onlinewebfonts.com/c/4c4a8b4f494bf44c6891cbd63789c881?family=Budmo+W00+Solid');
@import url('https://db.onlinewebfonts.com/c/690f1ec2426a50d0d059c20a6d9592e6?family=ScubaWeb-Bold+W03+Regular');

@font-face {
  font-family: "Budmo W00 Solid";
  src: url("./fonts/Budmo\ W00\ Solid.eot");
  src: url("./fonts/Budmo\ W00\ Solid.eot?#iefix")format("embedded-opentype"),
    url("./fonts/Budmo\ W00\ Solid.woff2")format("woff2"),
    url("./fonts/Budmo\ W00\ Solid.woff")format("woff"),
    url("./fonts/Budmo\ W00\ Solid.ttf")format("truetype"),
    url("./fonts/Budmo\ W00\ Solid.svg#Budmo W00 Solid")format("svg");
}

@font-face {
  font-family: "ScubaWeb-Bold W03 Regular";
  src: url("./fonts/ScubaWeb-Bold\ W03\ Regular.eot");
  src: url("./fonts/ScubaWeb-Bold\ W03\ Regular.eot?#iefix")format("embedded-opentype"),
    url("./fonts/ScubaWeb-Bold\ W03\ Regular.woff2")format("woff2"),
    url("./fonts/ScubaWeb-Bold\ W03\ Regular.woff")format("woff"),
    url("./fonts/ScubaWeb-Bold\ W03\ Regular.ttf")format("truetype"),
    url("./fonts/ScubaWeb-Bold\ W03\ Regular.svg#ScubaWeb-Bold W03 Regular")format("svg");
}

@font-face {
  font-family: "Allenoire";
  src: url("./fonts/Allenoire-Regular.otf")format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("./fonts/DIN.ttf")format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Din2";
  src: url("./fonts/DIN2.otf")format("opentype");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.poppins {
  font-family: "poppins", sans-serif;
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: Stripes;
  src: url('./assets/Stripes.woff') format('woff');
}

.octothorpe {
  font-family: "Stripes", "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
}

.allenoire{
  font-family: "Allenoire";
}

.din{
  font-family: "Din2" !important;
}

.scrollable {
  overflow: hidden;
  overflow-y: scroll;
}
.scrollable::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.scrollable::-webkit-scrollbar-thumb {
  background: white; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.budmo {
  font-family: "Budmo W00 Solid";
}

.scuba {
  font-family: "ScubaWeb-Bold W03 Regular";
}

.modal-title {
  color: #fff;
}

.caros {
  font-family: "Caros Soft Medium";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .bottom {
    position: "fixed";
    bottom: 0;
    width: "100%";
    z-index: 100
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}