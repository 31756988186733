.playerDiv {
    width:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header__section {
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.floatingTitle {
    text-align: center;
    font-size: larger;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    /* margin-bottom: "30px"; */
}

.player video {
    border-radius: 10px;
}